<script>
  import {
    gameDeck,
    reverseGameDeck,
    playableCards,
    acesStack,
    playBoard,
    convertRank,
    canPlay,
    getCard,
    deal,
    deck,
    aces,
    addCard
  } from "../Cards/Cards.js";

  let showChoice = false;
  let playableCard;
  let cardIndex;
  let plays;
  let lenPlayable

  function getAceUrl($acesStack, stackNumber) {
    const card = $acesStack[stackNumber];
    if (card == 0) {
      return "BlueBack";
    }
    return card.name;
  }

  function getNewCard(newCard) {
    const card = $gameDeck[$gameDeck.length - 1];
    if (card) {
      return `../assets/images/cards/BlueBack.svg`;
    }
  }

  function getPlayableCard(playable) {
    const card = $playableCards[$playableCards.length - 1];
    if (card) {
      return `../assets/images/cards/${card.name}.svg`;
    }
  }

  function showNewCard() {
    const newCard = $gameDeck[0];
    if (newCard) {
      $gameDeck = $gameDeck.slice(1);
      if ($playableCards[0] === "transparent") {
        $playableCards[0] = newCard;
        $playableCards = $playableCards;
      } else {
        const cards = [...$playableCards];
        cards.push(newCard);
        $playableCards = cards;
      }
    } else {
      $gameDeck = $playableCards;
      $playableCards = ["transparent"];
    }
  }

  function playCard() {
    plays = {
      acesCard: undefined,
      board: []
    };
    playableCard = $playableCards[$playableCards.length - 1];

    lenPlayable = $playableCards.length - 1;
    if (playableCard) {
      if (playableCard.rank == 1) {
        plays.acesCard = playableCard;

        $acesStack[playableCard.suit] = playableCard;
        $playableCards = $playableCards.slice(0, lenPlayable);
        return;
      } else {
        const stackCard = $acesStack[playableCard.suit];
        if (
          stackCard &&
          stackCard.rank &&
          stackCard.rank + 1 === playableCard.rank
        ) {
          plays.acesCard = playableCard;
        }
      }
      for (let i = 0; i < 7; i++) {
        const boardCard = $playBoard[i][$playBoard[i].length - 1];
        cardIndex = i;
        if (boardCard) {
          if (
            boardCard.rank - 1 == playableCard.rank &&
            boardCard.color !== playableCard.color
          ) {
            plays.board.push(playableCard);
            break;
          }
        }

        if (!boardCard && playableCard.rank === 13) {
          playableCard.visible = true;
          $playBoard[i].push(playableCard);
          $playBoard[i] = $playBoard[i];
          $playableCards = $playableCards.slice(0, lenPlayable);
          return;
        }
      }
    }
    if (hasAcesAndBoardPlays(plays)) {
      showChoice = true;
      return;
    }

    if (plays.acesCard) {
      $acesStack[playableCard.suit] = playableCard;
      $playableCards = $playableCards.slice(0, lenPlayable);
      return;
    }

    if (plays.board.length > 0) {
      playableCard.visible = true;
      $playBoard[cardIndex].push(plays.board[0]);
      $playBoard[cardIndex] = $playBoard[cardIndex];
      $playableCards = $playableCards.slice(0, lenPlayable);
    }
  }

  function hasAcesAndBoardPlays(plays) {
    if (plays.acesCard && plays.board.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  function useFoundation() {
    $acesStack[playableCard.suit] = playableCard;
    $playableCards = $playableCards.slice(0, lenPlayable);
    showChoice = false;
  }

  function useLayout() {
    playableCard.visible = true;
    $playBoard[cardIndex].push(plays.board[0]);
    $playBoard[cardIndex] = $playBoard[cardIndex];
    $playableCards = $playableCards.slice(0, lenPlayable);
    showChoice = false;
  }
</script>

<style>
  .outerContainer {
    display: flex;
    justify-content: space-around;
  }

  .cardDeck {
    margin-right: 15px;
    display: flex;
    width: 100px;
    justify-content: space-around;
  }

  .aces {
    width: 200px;
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
    margin-left: 15px;
    margin-right: 15px;
  }

  .faceDown {
    padding-left: 2px;
    padding-right: 2px;
  }

  .faceUp {
    padding-left: 2px;
    padding-right: 2px;
  }

  .newCard {
    width: 50px;
    height: 75px;
    overflow: hidden;
    box-shadow: 1px 1px 1px 1px #888888;
    border: 1px dotted #888888;
    background-position-x: 17px;
    background-position-y: 24px;
    background-repeat: no-repeat;
    background-size: 10px 20px;
    background-image: url("../assets/images/cards/BlueBack.svg");
  }

  .playableCard {
    width: 50px;
    height: 75px;
    overflow: hidden;
    border: 1px dotted #888888;
    background-position-x: 16px;
    background-position-y: 19px;
    background-repeat: no-repeat;
    background-size: 10px 30px;
    background-image: url("../assets/images/cards/BlueBack.svg");
  }

  .hearts,
  .spades,
  .diamonds,
  .clubs {
    width: 50px;
    height: 75px;
    padding-top: 2px;
    overflow: hidden;
    border: 1px solid black;
    box-shadow: 1px 1px 1px 1px #888888;
  }

  @media screen and (min-width: 600px) {
    .outerContainer {
      width: 600px;
    }
    .aces {
      margin-left: 100px;
      width: 500px;
    }
  }

  .modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: transparent;
    z-index: 10;
  }

  .commands {
    cursor: pointer;
    color: green;
    font-weight: bold;
    width: 120px;
  }

  .choose {
    position: relative;
    top: 325px;
    margin: auto auto;
    width: 300px;
    text-align: center;
    cursor: default;
    padding: 10px 10px;
    border: solid 2px red;
    background-color: rgb(255,255,255, 0.1);
  
  }
</style>

<div class="outerContainer">
  <div class="aces">
    <div class="hearts">
      <div class="card" draggable="true">
        <img
          src="../assets/images/cards/{getAceUrl($acesStack, 0)}.svg"
          width="50px"
          alt="Played Cards" />
      </div>
    </div>
    <div class="spades">
      <div class="card" draggable="true">
        <img
          src="../assets/images/cards/{getAceUrl($acesStack, 1)}.svg"
          width="50px"
          alt="Played Cards" />
      </div>
    </div>
    <div class="diamonds">
      <div class="card" draggable="true">
        <img
          src="../assets/images/cards/{getAceUrl($acesStack, 2)}.svg"
          width="50px"
          alt="Played Cards" />
      </div>
    </div>
    <div class="clubs">
      <div class="card" draggable="true">
        <img
          src="../assets/images/cards/{getAceUrl($acesStack, 3)}.svg"
          width="50px"
          alt="Played Cards" />
      </div>
    </div>
  </div>

  <div class="cardDeck">
    <div class="faceUp">
      <div
        class="playableCard"
        draggable="true"
        on:click={playCard}>
        <img src={getPlayableCard($playableCards)} width="50px" alt="" />
      </div>
    </div>
    <div class="faceDown">
      <div class="newCard" on:click={showNewCard}>
        <img
          src={getNewCard($gameDeck)}
          style="padding-top: 2px"
          width="50px"
          alt="" />
      </div>
    </div>
  </div>
</div>
{#if showChoice}
  <div class="modal-backdrop">
    <div class="choose">
      Where do you want to play the Card?
      <div style="padding-top: 10px;">
        <button class="commands" on:click={() => useFoundation()}>
          Foundation
        </button>
        <button class="commands" on:click={() => useLayout()}>Layout</button>
      </div>
    </div>
  </div>
{/if}
