import { writable, get } from 'svelte/store';

const _cards = [
  '2_of_clubs',
  '2_of_diamonds',
  '2_of_hearts',
  '2_of_spades',
  '3_of_clubs',
  '3_of_diamonds',
  '3_of_hearts',
  '3_of_spades',
  '4_of_clubs',
  '4_of_diamonds',
  '4_of_hearts',
  '4_of_spades',
  '5_of_clubs',
  '5_of_diamonds',
  '5_of_hearts',
  '5_of_spades',
  '6_of_clubs',
  '6_of_diamonds',
  '6_of_hearts',
  '6_of_spades',
  '7_of_clubs',
  '7_of_diamonds',
  '7_of_hearts',
  '7_of_spades',
  '8_of_clubs',
  '8_of_diamonds',
  '8_of_hearts',
  '8_of_spades',
  '9_of_clubs',
  '9_of_diamonds',
  '9_of_hearts',
  '9_of_spades',
  '10_of_clubs',
  '10_of_diamonds',
  '10_of_hearts',
  '10_of_spades',
  'jack_of_clubs2',
  'jack_of_diamonds2',
  'jack_of_hearts2',
  'jack_of_spades2',
  'queen_of_clubs2',
  'queen_of_diamonds2',
  'queen_of_hearts2',
  'queen_of_spades2',
  'king_of_clubs2',
  'king_of_diamonds2',
  'king_of_hearts2',
  'king_of_spades2',
  'ace_of_clubs',
  'ace_of_diamonds',
  'ace_of_hearts',
  'ace_of_spades',
];

let deck = [];
const discard = [];
let play = [[], [], [], [], [], [], []];
let aces = [[0], [0], [0], [0]];
let init = false;

const gameDeck = writable();
const reverseGameDeck = writable();
const playableCards = writable();
const playBoard = writable();
const acesStack = writable(aces);
const won = writable(false);

function shuffle() {
  let array = Array();
  let i = 0;
  let j = 0;
  let temp = null;
  array = [..._cards];

  for (i = array.length - 1; i > 0; i -= 1) {
    j = Math.floor(Math.random() * (i + 1));
    temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }

  const ary = array.map((c, index) => {
    return {
      name: c,
      visible: false,
      rank: convertRank(c),
      color: convertColor(c),
      suit: convertSuit(c),
      top: 20,
    };
  });

  return ary;
}

function getCard() {
  if (init === false) {
    deck = shuffle();
    init = true;
  }
  const card = deck.pop();
  return card;
}

function setLastCardVisible(play) {
  for (let i = 0; i < 7; i++) {
    play[i][play[i].length - 1].visible = true;
  }
}

function deal(start) {
  init = !start;
  deck = [];
  play = [[], [], [], [], [], [], []];
  aces = [[0], [0], [0], [0]];

  let col = 0;
  let item = 0;
  while (col < 7) {
    play[item][col] = getCard();
    item++;
    if (item > 6) {
      col++;
      item = col;
    }
  }

  acesStack.set(aces);
  gameDeck.set(deck);
  reverseGameDeck.set(deck.reverse());
  playableCards.set(['transparent']);
  setLastCardVisible(play);
  playBoard.set(play);
  won.set(false);
}

function convertRank(card) {
  let rank = card.match(/10/);
  if (rank) {
    return parseInt(rank.toString());
  }
  let reg = /^[0-9]+/;
  rank = card.match(reg);
  if (rank) {
    return parseInt(rank.toString());
  }
  reg = /^[a-z]+/;
  rank = card.match(reg);
  if (rank) {
    rank = rank.toString();
    switch (rank) {
      case 'ace':
        return 1;
      case 'jack':
        return 11;
      case 'queen':
        return 12;
      case 'king':
        return 13;
    }
  }
}

function convertColor(card) {
  if (card.includes('spades') || card.includes('clubs')) {
    return 'black';
  } else {
    return 'red';
  }
}

function convertSuit(card) {
  let suit;

  if (card.includes('spades')) {
    suit = 1;
  } else if (card.includes('clubs')) {
    suit = 3;
  } else if (card.includes('hearts')) {
    suit = 0;
  } else {
    suit = 2;
  }
  return suit;
}

function addCard(index, card){
  playboard.update((array) => {
    array[index].push(card)
  })
}

function playable(playBoardStore, cardToPlay, storeNumber) {
  if (playBoardStore[storeNumber].length === 0) {
    return;
  }
  const card =
    playBoardStore[storeNumber][playBoardStore[storeNumber].length - 1];
  if (cardToPlay.color !== card.color && cardToPlay.rank === card.rank - 1) {
    return { card, storeNumber };
  }
}

function canPlay(card, playBoardStore) {
  const can = [];

  for (let i = 0; i < 7; i++) {
    const playableValues = playable(playBoardStore, card, i);
    if (playableValues) {
      can.push(playableValues);
    }
  }
  return can;
}

export {
  gameDeck,
  reverseGameDeck,
  playableCards,
  acesStack,
  playBoard,
  convertRank,
  canPlay,
  getCard,
  deal,
  deck,
  aces,
  won,
  addCard
};
