<script>
  import { setContext } from "svelte";
  import Play from "./Components/Play/Play.svelte";
  import TopFields from "./Components/Play/TopFields.svelte";
  import {
    gameDeck,
    playBoard,
    deal,
    deck,
    getCard,
  } from "./Components/Cards/Cards.js";
  import ModalContact from "./Components/ModalContact.svelte";

  setContext("close", closeModal);

  let loadModal = false;

  function loadContact() {
    loadModal = true;
  }

  function closeModal() {
    loadModal = false;
  }

  function newGame() {
    deal(true);
  }
  deal(true);
</script>

<style>
  header {
    position: fixed;
    left: 0;
    top: 0px;
    height: 50px;
    width: 100%;
    background-color: white;
  }

  main {
    position: fixed;
    font-size: 14pt;
    left: 0;
    top: 40px;
    width: 100%;
  }

  footer {
    position: fixed;
    left: 0;
    bottom: 0;
    height: 40px;
    width: 100%;
  }

  .header {
    margin: auto;
    width: 100%;
    height: 30px;
    position: relative;
    font-size: 18pt;
    font-weight: bold;
    border-bottom: solid 1px black;
    display: grid;
    grid-template-columns: 60% 40%;
  }

  .content {
    margin: auto;
    width: 100%;
    height: calc(100vh - 101px);
    overflow-y: auto;
  }

  .footer {
    margin: auto;
    width: 100%;
    height: 40px;
    padding-left: 2px;
    padding-top: 5px;
    border-top: solid 1px black;
    display: grid;
    grid-template-columns: 33% 34% 33%;
  }

  @media screen and (min-width: 600px) {
    .header,
    .content,
    .footer {
      margin: auto;
      width: 600px;
    }

    main {
      font-size: 16pt;
    }
  }

  button {
    font: inherit;
    border: 1px solid #cf0056;
    background: #cf0056;
    padding: 4px 8px;
    color: white;
    border-radius: 5px;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.26);
    cursor: pointer;
    text-decoration: none;
  }

  button:focus {
    outline: none;
  }

  button:hover,
  button:active {
    background: #e40763;
    border-color: #e40763;
    box-shadow: 1px 1px 8px rgba(77, 51, 51, 0.26);
  }
</style>

<header>
  <div class="header">
    <div style="text-align: left; padding-left: 5px;">
      <img
        src="./assets/images/cards/ace_of_spades.svg"
        alt="logo"
        style="height: 20px;" />
      Solitaire
    </div>
    <div
      style="text-align: right; padding-right: 5px; font-size: 12px;"
      on:click={() => loadContact()}>
      <span
        style="padding-top: 7px; display: inline-block; vertical-align: middle;
        cursor: pointer;">
        Contact/Donate
      </span>
    </div>
  </div>
</header>

<main>
  <div class="content">
    <TopFields />
    <Play />
  </div>
</main>

<footer>
  <div class="footer">
    <div>&nbsp;</div>
    <div style="text-align: right">
      <button on:click={newGame}>New Game</button>
    </div>
    <div
      style="text-align: right; font-size: 6pt; padding-top: 10px;
      padding-right: 3px;">
      Copyright 2020 Rich Brown, LLC
    </div>

  </div>
</footer>
{#if loadModal === true}
  <ModalContact title="Contact" on:exit={closeModal}>
    <div>Contact</div>
  </ModalContact>
{/if}
