<script>
  import { createEventDispatcher } from "svelte";
  import {
    gameDeck,
    playBoard,
    acesStack,
    convertRank,
    canPlay,
    getCard,
    deal,
    deck,
    won
  } from "../Cards/Cards.js";

  const dispatch = createEventDispatcher();

  const top = [[], [], [], [], [], [], []];

  $: {
    let hasHidden = false;
    for (let i = 0; i < 7; i++) {
      for (let j = 0; j < $playBoard[i].length; j++) {
        if ($playBoard[i][j].visible === false) {
          hasHidden = true;
          break;
        }
      }
      if (hasHidden) {
        break;
      }
    }
    if (!hasHidden) {
      $won = true;
    }
  }

  function isLastCard(boardNumber, index) {
    if (index === $playBoard[boardNumber].length - 1) {
      return true;
    }
    return false;
  }

  function playOnAce(boardNumber, card, index) {
    if (card.rank === 1) {
      $acesStack[card.suit] = card;
    } else {
      const stackCard = $acesStack[card.suit];
      if (stackCard && stackCard.rank && stackCard.rank + 1 === card.rank) {
        $acesStack[card.suit] = card;
      } else {
        return false;
      }
    }
    $playBoard[boardNumber] = $playBoard[boardNumber].splice(
      0,
      $playBoard[boardNumber].length - 1
    );
    if ($playBoard[boardNumber].length > 0) {
      $playBoard[boardNumber][
        $playBoard[boardNumber].length - 1
      ].visible = true;
    }
    return true;
  }

  function resetTop(stackNumber) {
    const stack = $playBoard[stackNumber];
    for (let i = 0; i < stack.length; i++) {
      if (i === 0) {
        stack[0].top = 0;
      } else {
        if (stack[i].visible === false) {
          stack[i].top = stack[i - 1] + 15;
        } else {
          if (stack[i - 1].visible === false) {
            stack[i].top = stack[i - 1].top + 15;
          } else {
            stack[i].top = stack[i - 1].top + 25;
          }
        }
      }
    }
    $playBoard[stackNumber] = stack;
  }

  function getTop(index, card, stack) {
    if (index === 0) {
      top[stack][index] = 0;
      card.top = 0;
    } else {
      if (card.visible === false) {
        top[stack][index] = top[stack][index - 1] + 15;
        card.top = top[stack][index - 1] + 15;
      } else {
        if ($playBoard[stack][index - 1].visible === false) {
          top[stack][index] = top[stack][index - 1] + 15;
          card.top = top[stack][index - 1] + 15;
        } else {
          top[stack][index] = top[stack][index - 1] + 25;
          card.top = top[stack][index - 1] + 25;
        }
      }
    }
    return `${card.top}px`;
  }

  function getSrc(card) {
    if (card.visible) {
      return card.name;
    }
    return "BlueBack";
  }

  function moveStack(fromStack, toStack) {
    const [board, fromCard, index] = fromStack; // Destructuring Array
    const { card, storeNumber } = toStack; /// Destructuring Object
    const stackToMove = $playBoard[board].slice(index);
    $playBoard[board] = $playBoard[board].slice(0, index);
    if ($playBoard[board].length > 0) {
      $playBoard[board][$playBoard[board].length - 1].visible = true;
    }
    $playBoard[storeNumber] = $playBoard[storeNumber].concat(stackToMove);
    resetTop(storeNumber);
  }

  function moveToEmpty(kingStack, slot) {
    const [board, fromCard, index] = kingStack; // Destructuring Array
    const stackToMove = $playBoard[board].slice(index);

    $playBoard[board] = $playBoard[board].slice(0, index);
    if ($playBoard[board].length > 0) {
      $playBoard[board][$playBoard[board].length - 1].visible = true;
    }
    $playBoard[slot] = $playBoard[slot] = stackToMove;
  }

  function freeSlot() {
    for (let i = 0; i < 7; i++) {
      if ($playBoard[i].length === 0) {
        return i;
      }
    }
  }

  function click(boardNumber, card, index) {
    let playableTargets;
    let source;
    let target;
    if (card.visible === false) {
      return;
    }

    if (!source) {
      if (isLastCard(boardNumber, index)) {
        const result = playOnAce(boardNumber, card, index);
        if (result) {
          return;
        }
      }
      if (card.rank === 13) {
        const slot = freeSlot();
        if (slot >= 0) {
          const kingStack = [boardNumber, card, index];
          moveToEmpty(kingStack, slot);
          return;
        }
      }
      playableTargets = canPlay(card, $playBoard);
      if (playableTargets) {
        source = [boardNumber, card, index];
        if (playableTargets.length === 1) {
          moveStack(source, playableTargets[0]);
        } else if (playableTargets.length === 2) {
          if (playableTargets[0].storeNumber < playableTargets[1].storeNumber) {
            moveStack(source, playableTargets[0]);
          } else {
            moveStack(source, playableTargets[1]);
          }
        }
        return;
      }
    } else {
      if (card.name === source.name) {
        source = undefined;
        return;
      }
    }
  }
</script>

<style>
  .outerContainer {
    position: relative;
    top: 30px;
    display: flex;
    justify-content: space-around;
  }

  .column {
    /* border: 1px solid black; */
    height: 100px;
    width: 40px;
    position: relative;
  }

  .card {
    position: absolute;
    left: 0;
    height: 55px;
    width: 40px;
    overflow: hidden;
    background-color: white;
    border-radius: 4px;
    box-shadow: 1px 1px 1px 1px #888888;
  }

  img {
    width: 70px;
  }

  .won {
    position: relative;
    top: 200px;
    width: 300px;
    text-align: center;
    margin: 0 auto;
    background-color: whitesmoke;
    border: 1px solid black;
    z-index: 20;
  }

  .hidden {
    display: none;
  }
</style>

<div class="outerContainer">
  <div class="column one">
    {#each $playBoard[0] as card, index}
      <div class="card" style="top:{getTop(index, card, 0)}">
        <img
          src="../assets/images/cards/{getSrc(card)}.svg"
          on:click={() => {
            click(0, card, index);
          }}
          alt={card.name} />
      </div>
    {/each}
  </div>
  <div class="column two">
    {#each $playBoard[1] as card, index}
      <div class="card" style="top:{getTop(index, card, 1)}">
        <img
          src="../assets/images/cards/{getSrc(card)}.svg"
          on:click={() => {
            click(1, card, index);
          }}
          alt={card} />
      </div>
    {/each}
  </div>
  <div class="column three">
    {#each $playBoard[2] as card, index}
      <div class="card" style="top:{getTop(index, card, 2)}">
        <img
          src="../assets/images/cards/{getSrc(card)}.svg"
          on:click={() => {
            click(2, card, index);
          }}
          alt={card} />
      </div>
    {/each}
  </div>
  <div class="column four">
    {#each $playBoard[3] as card, index}
      <div class="card" style="top:{getTop(index, card, 3)}">
        <img
          src="../assets/images/cards/{getSrc(card)}.svg"
          on:click={() => {
            click(3, card, index);
          }}
          alt={card} />
      </div>
    {/each}
  </div>
  <div class="column five">
    {#each $playBoard[4] as card, index}
      <div class="card" style="top:{getTop(index, card, 4)}">
        <img
          src="../assets/images/cards/{getSrc(card)}.svg"
          on:click={() => {
            click(4, card, index);
          }}
          alt={card} />
      </div>
    {/each}
  </div>
  <div class="column six">
    {#each $playBoard[5] as card, index}
      <div class="card" style="top:{getTop(index, card, 5)}">
        <img
          src="../assets/images/cards/{getSrc(card)}.svg"
          on:click={() => {
            click(5, card, index);
          }}
          alt={card} />
      </div>
    {/each}
  </div>
  <div class="column seven">
    {#each $playBoard[6] as card, index}
      <div class="card" style="top:{getTop(index, card, 6)}">
        <img
          src="../assets/images/cards/{getSrc(card)}.svg"
          on:click={() => {
            click(6, card, index);
          }}
          alt={card} />
      </div>
    {/each}
  </div>
</div>
<div class={$won ? 'won' : 'hidden'}>
  <div>All of the cards will play now.</div>
  <div style="font-weight: bold; color: red">YOU HAVE WON!</div>
</div>
