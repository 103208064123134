<style>
  .modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.75);
    z-index: 10;
  }

  .modal {
    position: relative;
    top: 12px;
    width: 290px;
    margin: auto;
    max-height: 90%;
    background: white;
    border-radius: 5px;
    z-index: 100;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    overflow-x: hidden;
    overflow-y: auto;
  }

  h3 {
    padding: 1rem;
    margin: 0;
    border-bottom: 1px solid #ccc;
    font-family: 'Roboto Slab', sans-serif;
  }

  .content {
    padding: 1rem;
    text-align: center;
  }

  footer {
    padding: 1rem;
  }

  div.form {
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 12px;
  }

  @media (min-width: 500px) {
    .modal {
      width: 450px;
    }
  }

  @media (min-width: 700px) {
    .modal {
      width: 600px;
    }
  }
</style>

<script>
  import { getContext } from 'svelte'
  import { fly, fade } from 'svelte/transition'

  export let title

  const closeModal = getContext('close')

  function sendEmail() {
    window.open('mailto:rich@richbrown.com?subject=Nine-Down%20Contact')
    closeModal()
  }

  function close() {
    closeModal()
  }
</script>

<div transition:fade class="modal-backdrop" on:click="{close}">
  <div transition:fly="{{ y: 300 }}" class="modal">
    <h3>{title}</h3>
    <div class="content">
      Have comments?
      <div
        style="color: blue; text-decoration: underline;"
        on:click="{sendEmail}"
      >
        Click to send us an email.
      </div>
    </div>
    <div class="form">
      Did you enjoy playing this game? Did you notice that there were NO ads?
    </div>
    <div class="form">
      If you enjoyed playing the games and hate ads, please consider donating
      through PayPal so that I can make more ad-free games.
    </div>
    <div class="form">Donate or not, enjoy the game!</div>
    <div style="text-align: center; width: 95%; margin: auto auto">
      <form
        action="https://www.paypal.com/cgi-bin/webscr"
        method="post"
        target="_top"
      >
        <input type="hidden" name="cmd" value="_s-xclick" />
        <input type="hidden" name="hosted_button_id" value="XDTTRP46N3GCQ" />
        <input
          type="image"
          src="https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif"
          border="0"
          name="submit"
          title="PayPal - The safer, easier way to pay online!"
          alt="Donate with PayPal button"
        />
        <img
          alt=""
          border="0"
          src="https://www.paypal.com/en_US/i/scr/pixel.gif"
          width="1"
          height="1"
        />
      </form>
    </div>
    <footer>
      <slot name="footer">
      <div style="text-align: center">
        <button style="padding: 0 5px" on:click="{closeModal}">Close</button>
        </div>
      </slot>
    </footer>

  </div>
</div>
